section.dashboard {
    display: flex;
    grid-column-gap: 19px;
    height: inherit;
    padding: 0% 2.5%;
    color: white;
    overflow: hidden;
    width: 100%;
    min-height: 34em;
}

section.dashboard .comment-card {
    cursor: pointer;
}

.dashboard > * {
    max-height: 100%;
    /* overflow: scroll; */
    display: flex;
    flex-direction: column;
}

#left {
    border-radius: 13px;
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    flex-grow: 1;
    min-width: 20em;
    max-width: 25em;
}

#right,
#middle {
    border-radius: 13px;
    max-height: inherit;
}

#middle {
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(156, 156, 169, 0.2);
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    padding: 2vw 2vw 0;
    grid-area: middle;
    flex-grow: 2;
    min-width: 20em;
    width: 47%;
}

#right {
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(156, 156, 169, 0.2);
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    border-radius: 13px;
    padding: 2vw 2vw 0;
    flex-grow: 1;
    max-width: 25em;
}

/* Style the counter cards */

.left-card {
    padding: 2vw 2vw 0;
    background: rgba(44, 44, 44, 0.28);
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    border-radius: 13px;
    color: white;
    box-shadow: 0px 1rem 1.5rem rgb(0 0 0 / 50%);
    border: 1px solid rgba(156, 156, 169, 0.2);
}

.right-header {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.twitter-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.twitter-header-title {
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.2;
}

.handle {
    font-size: 15px;
    line-height: 22px;
    color: rgba(156, 156, 169, 0.74);
    margin: 0 0 0 auto;
}

.tweet-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    margin: 10px 0px;
    background: rgba(44, 44, 44, 0.28);
    backdrop-filter: blur(29px);
    border-radius: 13px;
    color: white;
    height: inherit;
}

.proposals-text {
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.2;
    margin: 0;
}

.price-graph-2 {
    display: none;
}

@media all and (max-width: 1200px) {
    section.dashboard {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 19px;
        grid-row-gap: 19px;
    }

    #left {
        grid-area: 1 / 1 / 7 / 2;
        min-width: none;
        max-width: none;
        width: 100%;
    }

    #middle {
        grid-area: 1 / 2 / 4 / 3;
        min-width: none;
        max-width: none;
        width: 100%;
    }

    #right {
        grid-area: 4 / 2 / 7 / 3;
        min-width: none;
        max-width: none;
        width: 100%;
    }
}

/* Stack the layout on small devices/viewports. */
@media all and (max-width: 797px) {
    section.dashboard {
        display: flex;
        flex-direction: column;
        overflow: visible;
        height: auto;
        max-height: none;
        margin: 0 auto;
    }
    section > * {
        max-height: none;
        overflow: visible;
    }
    #right {
        backdrop-filter: blur(5px);
    }
    #left,
    #middle {
        backdrop-filter: blur(5px);
    }

    .left-card {
        display: none;
    }

    .price-graph-2 {
        display: initial;
        background: transparent;
    }
}
