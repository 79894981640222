button {
  background: none;
  border: none;
  cursor: pointer;
}

.price-card {
  color: #fff;
  max-width: inherit;
  background: transparent;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.card-inner {
  /* position: relative; */
}

.top-data {
  padding: 10px;
}

.crypto-price {
  float: right;
  font-style: normal;
  font-weight: 400;
  font-size: 20.89px;
  line-height: 22px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.crypto-price span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  position: relative;
  padding: 0;
  margin-top: 0.8em;
}

.positive {
  color: #41e041;
}

.negative {
  color: #ff5b5b;
}

/* Pow Data graph */

.chart {
  position: relative;
  width: 100%;
  padding-top: 50%;
}

.chart > * {
  position: absolute !important;
  top: 0;
  height: 100%;
  width: 100%;
}

.chart svg {
  vertical-align: middle;
}

.chart-actions {
  display: flex;
  justify-content: stretch;
  flex-wrap: nowrap;
}

.chart-actions button {
  color: rgba(156, 156, 169, 0.74);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;
  margin: 0;
  width: 100%;
  padding: 0;
  transition: 0.2s ease;
}

.chart-actions button:hover,
.chart-actions button:focus {
  color: #fff;
}

.chart-actions .active {
  color: #fff;
}

.loading-container {
  height: 100%;
  padding-bottom: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container span {
  position: absolute;
  left: 50%;
  margin-left: -35px;
  top: 30%;
}

/*  devices/viewports. */
@media all and (max-width: 1500px) {
  .chart-actions button {
    margin: 0 10px;
  }
  .crypto-price {
    font-size: 1.1em;
    line-height: 18px;
    margin-top: 2px;
  }

  .crypto-price span {
    font-size: 0.5em;
  }
  .top-data {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  .pow-image {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}

/* Stack the layout on small devices/viewports. */
@media all and (max-width: 900px) {
  .crypto-price {
    font-size: 1.4em;
    line-height: 20px;
  }
  .crypto-price span {
    font-size: 0.55em;
  }
}
