/* MAIN TREASURY SECTION */
#treasury-section {
    padding: 0 10%;
}

#treasury-navbar {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

#tab-options {
    display: flex;
    width: 50%;
}

#tab-options-mobile {
    display: none;
}

.tab-option {
    margin-right: 3%;
    /* margin-bottom: 2%; */
    color: #9c9ca9bd;
}

.tab-option:hover {
    color: white;
    text-decoration: underline;
    text-underline-offset: 70%;
    cursor: pointer;
}

#walletID {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 7px;
    height: 28px;
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(231, 246, 255, 0.2);
    font-weight: 500;
    font-size: 11px;
    border-radius: 6px;
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: 0.2s ease; 
}

#walletID:hover,
#walletID:focus {
    background-color: rgba(231, 246, 255, 0.1);
}

/* PORTFOLIO SECTION */

.tpt-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10%;
    margin-bottom: 3%;
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(231, 246, 255, 0.2);
    backdrop-filter: blur(29px);
    border-radius: 13px;
}

.tpt-top-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.tpt-balance { 
    margin-bottom: 1.2em;
}

.tpt-top-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(231, 246, 255, 0.74);
    margin-bottom: 1%;
}

.tpt-percentage-bar {
    display: flex;
    flex-direction: row;
}

.tpt-crypto-percent {
    display: flex;
    flex-direction: row;
}

.tpt-text-percent {
    font-weight: 700;
    font-size: 13px;
    width: 100px;
    padding-top: 2%;
    padding-right: 2%;
}

.tpt-top-disclaimer {
    font-weight: 500;
    font-size: 11px;
    line-height: 174%;
    color: rgba(231, 246, 255, 0.74);
}

.tpt-planet-image {
    width: 237px;
    height: 237px;
}

.tpt-bottom {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 2%;
}

.tpt-bottom > div:first-of-type {
    margin-right: 1.5%;
}

/* PORTFOLIO CRYPTO CARD */

.portfolio-crypto-card {
    background: rgba(44, 44, 44, 0.28);
    width: 100%;
    border: 1px solid rgba(231, 246, 255, 0.2);
    backdrop-filter: blur(29px);
    border-radius: 13px;
}

.pcc-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15% 0 25% 0;
    text-align: center;
}

.pcc-top > * {
    padding: 2%;
}

.pcc-bottom {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 4%;
    justify-content: space-between;
    background: rgba(112, 112, 124, 0.2);
    cursor: pointer;
}

.pcc-bottom > p {
    color: white;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
}

.pcc-bottom > a {
    color: white;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
}

.pcc-bottom > a:hover {
    text-decoration: underline;
}

.pcc-bottom > a > img {
    width: 12px;
    height: 12px;
    margin: 0 5px;
}

/* NFT SECTION */
.treasury-nft-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 110%; */
}
.nft-card {
    flex: 20%;
}
.nft-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 8px;
}

.nft-desc {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 13px;
    padding: 10px 0;
}

.nft-link {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}

.nft-card a {
    font-size: 13px;
}

/* HISTORY SECTION */
.history-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 19%;
}

.history-card-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 18%;
}

@media (max-width: 1180px) {
    /* everything smaller than a laptop */
    /* .content {
        height: calc(100vh - 100px);
    } */
    .pcc-top {
        margin: 10% 0 10% 0;
    }
    /* .pcc-top {
        
        margin-bottom: 25%;
    } */
    .portfolio-crypto-card {
        width: 50%;
    }
    .nft-desc {
        line-height: 18px;
    }
    .nft-link {
        margin-left: 5px;
    }
}
@media screen and (max-width: 900px) {
   
}
@media screen and (max-width: 797px) {
    .portfolio-crypto-card {
        width: 50%;
    }
    #treasury-navbar {
        width: 90%;
    }
    .treasury-nft-tab {
        overflow-y: auto;
        max-height: 755px;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        /* height: inherit; */
        border-radius: 13px;
    }

    .treasury-nft-tab::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .nft-card {
        flex: 40%;
    }
    .nft-title {
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
    }
    .nft-desc {
        line-height: 18px;
    }
    .pcc-top h1 {
        font-size: 20px;
    }
    .pcc-top span {
        font-size: 12px;
    }
}

@media (max-width: 460px) {
    #walletID {
        position: relative;
    }
    .content {
        height: 100%;
    }

    #tab-options {
        display: none;
    }

    #tab-options-mobile {
        display: initial;
        position: relative;
        margin-right: 20px;
    }

    /* .select-overlay {
    border: 1px solid rgba(156, 156, 169, 0.4);
    border-radius: 54px;
    background: transparent;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 8px 8px 10px;
    margin-right: 10px;
  } */

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 5px;
        border: 1px solid rgba(156, 156, 169, 0.4);
        border-radius: 2px;
        margin-right: 2rem;
        padding: 8px 8px 8px 10px;
        padding-right: 2rem;
        color: white;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        border-radius: 54px;
    }

    select > * {
        border-radius: 15px;
        background-color: red;
    }
    .css-qc6sy-singleValue {
        color: white !important;
    }

    #react-select-3-listbox {
        background: black;
    }

    .tpt-top {
        flex-direction: column-reverse;
        width: 90%;
        height: 100%;
        margin-top: 5%;
    }

    .portfolio-crypto-card {
        width: 50%;
    }
    .pcc-top img {
        height: 35px;
    }

    .pcc-top h1 {
        font-size: 15px;
    }
    .pcc-top span {
        font-size: 10px;
    }
    .pcc-bottom {
        display: block;
        text-align: center;
    }
    .pcc-bottom > p {
        font-size: 11px;
    }
    .pcc-bottom > a > img {
        width: 10px;
        height: 10px;
    }
    .tpt-planet-image {
        width: 182.58px;
        height: 182.58px;
        display: block;
        margin: auto;
    }
    .tpt-top {
        backdrop-filter: none;
        width: 100%;
    }

    .tpt-top-h1 {
        font-size: 28px;
        margin: 2% 0;
    }
    .tpt-top-info {
        width: 100%;
    }

    .tpt-top-text {
        font-size: 12px;
        margin: 2% 1% 1% 1%;
    }

    .tpt-percentage {
        margin: 5% 1%;
    }
    .tpt-crypto-percent {
        margin-top: 3%;
    }
    .tpt-text-percent {
        font-size: 11px;
    }
    .portfolio-crypto-card {
        width: 100%;
    }
    #treasury-navbar {
        width: 85%;
    }
}

@media (max-width: 375px) {
    /* mini */
    .treasury-content {
        width: 100%;
    }
}
