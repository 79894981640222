.connect-card {
  max-width: inherit;
  background-color: #ffffff00;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(156, 156, 169, 0.2);
  border-radius: 13px;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  font-family: aktiv-grotesk, sans-serif;
  margin-bottom: 18px;
  align-items: center;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24.8889px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.connect-card .description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  color: #ffffff;
  padding: 10px 0 40px 0;
}

.connect-card .actions {
  text-align: center;
  padding: 0;
}

@media all and (max-width: 797px) {
  .connect-card {
    height: auto;
    overflow: visible;
    padding: 10% 5%;
    margin-bottom: 0;
  }
}
