.planetary-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.7vw;
  gap: 1.7vw;
  flex-grow: 1;
  width: 65%;
}

.planetary-voting-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10%;
  grid-gap: 5%;
}

.planetary-voting-sub {
  background: rgba(44, 44, 44, 0.28);
  border: 1px solid rgba(231, 246, 255, 0.2);
  border-radius: 13px;
  padding: 32px;
}

@media (max-width: 900px) {
  .planetary-voting-section-container {
    flex-direction: column;
  }
  .planetary-voting-section-container .planetary-info-container {
    align-self: center;
  }
  .voting-container {
    margin: 0;
  }
}

@media screen and (max-width: 797px) {
  .planetary-grid-container {
    grid-template-columns: none;
  }
}
