.card {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    padding: 14px;
    background: rgba(112, 112, 124, 0.2);
    border: 1px solid transparent;
    border-radius: 13px;
}

.vote-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    margin-bottom: 17px;
}

.vote-flex-container > h2 {
    color: white;
    font-weight: 700;
    font-size: 1.25em;
    line-height: 22px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttons-container > *:last-child {
    margin-bottom: 0;
}

.vote-button {
    background: #ffffff;
    border-radius: 38px;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 8rem; */
    height: 2.5rem;
    gap: 12px;
    margin-bottom: 13px;
}

.vote-button > span {
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;
}

.vote-button-text {
    font-weight: 500;
    font-size: 1.25em;
    line-height: 16px;
}

/* button:disabled:not(last-child),
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
} */

.abstain-button {
    border-radius: 38px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 8rem; */
    height: 2.5rem;
    gap: 12px;
    margin-bottom: 13px;
    border: 2px solid rgba(156, 156, 169, 0.2);
    border-radius: 38px;
}

.abstain-button > span {
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 16px;
}

.votes-amount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 0.6em;
    line-height: 174%;
}
.tooltip {
    position: relative;
    cursor: pointer;
}
.tooltip-text {
    position: absolute;
    transform: translateX(-50%);
    background-color: rgb(22, 21, 21);
    color: #fff;
    backdrop-filter: blur(29px);
    border: 1px solid rgba(156, 156, 169, 0.2);
    white-space: nowrap;
    padding: 3px 7px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.tooltip:hover .tooltip-text {
    top: -260%;
    left: -300%;
    visibility: visible;
    opacity: 1;
}
.vote-state-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.vote-state-container > h2 {
    color: white;
    font-weight: 700;
    font-size: 1.25em;
    line-height: 22px;
    margin-left: 15px;
}

.change-to-green {
    background: rgb(66, 221, 52);
}

.change-to-red {
    background: rgb(221, 52, 52);
}

.change-to-grey {
    background: rgb(105, 105, 105);
}

.no-pointer {
    cursor: default !important;
}

.sm-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.6em;
    line-height: 174%;
    color: #ffffff;
}

.vote-decision-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
}

.vote-decision {
    display: flex;
    flex-direction: column;
}

.vote-decision h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25em;
    line-height: 22px;
    color: #ffffff;
}

.vote-decision small {
    font-style: normal;
    font-weight: 400;
    font-size: 0.6em;
    line-height: 174%;
    color: #ffffff;
}

.loading-vote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
