.container-proposals {
    display: flex;
    flex-direction: row;
    padding: 0% 2.5%;
    width: 100%;
    overflow: hidden;
    height: inherit;
}

.content {
    color: #f1f1f1;
    flex: 83% 1;
    display: flex;
    flex-direction: column;
}

.sidebar {
    flex: 17%;
    max-width: 285px;
    margin-right: 2%;
    max-height: 100%;
    background-color: inherit;
    overflow: hidden;
    overflow-y: auto;
}

.sidebar a {
    display: block;
    color: white;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(156, 156, 169, 0.4);
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    border-radius: 52px;
    padding: 4%;
    margin-bottom: 0.3em;
    cursor: pointer;
    transition: 0.2s ease;
}

.sidebar a.active {
    border: 1px solid #ffffff;
    color: white;
}

.sidebar a:hover:not(.active) {
    border-color: rgba(156, 156, 169, 1);
}

.col {
    float: left;
}

.link > .images {
    width: 30%;
}

.links > .planet-text {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    padding-left: 5px;
}

.links > .planet-number {
    width: 16px;
    padding: 2px 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(156, 156, 169, 0.74);
    justify-content: flex-end;
}

.links > .planet-number img {
    opacity: 0.5;
    transition: 0.2s ease;
}

.links > .planet-number img:hover {
    opacity: 1;
}

/* Clear floats after the columns */
.links:after {
    content: '';
    display: table;
    clear: both;
}

.tooltip4 {
    position: relative;
    cursor: pointer;
}
.tooltip-text4 {
    position: absolute;
    transform: translateX(-50%);
    font-size: 0.6em;
    background-color: rgb(22, 21, 21);
    color: #fff;
    backdrop-filter: blur(29px);
    border: 1px solid rgba(156, 156, 169, 0.2);
    white-space: nowrap;
    padding: 3px 7px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.tooltip4:hover .tooltip-text4 {
    top: -25%;
    left: -350%;
    visibility: visible;
    opacity: 1;
}

/* UPDAO */
.planet-switch {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 245, 231, 0.2);
    border-radius: 54px;
}

.planet-switch > img {
    margin-left: 20px;
}

@media (max-width: 1180px) {
    /* everything smaller than a laptop */
    .sidebar.dropdown {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 70px;
        left: 2.5vw;
        width: 95vw;
        height: calc(100vh - 70px - 2.5vw);
        background: rgba(20, 20, 20, 0.8);
        -webkit-backdrop-filter: blur(5px) brightness(1%);
        backdrop-filter: blur(5px) brightness(1%);
        border: 1px solid rgba(156, 156, 169, 0.2);
        border-radius: 13px;
        z-index: 30;
        animation: slidein 0.5s;
        padding: 3% 3% 0 3%;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 13px;
        max-width: 100vw;
    }
    .sidebar.dropdown::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}
@media screen and (max-width: 900px) {
    .sidebar {
        display: none;
    }

    .sidebar a {
        padding: 2%;
    }
}

@media screen and (max-width: 797px) {
    .container-proposals {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .content {
        color: #f1f1f1;
        flex: 83%;
    }

    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar a {
        float: left;
    }
    div.content {
        margin-left: 0;
    }
    .sidebar.dropdown {
        left: 15px;
        width: 94%;
    }
}
