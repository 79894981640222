/* ALL THE STYLING FOR AddCommentModal.tsx is from the createProposalModal */
.create-comment-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(29px);
    overflow: auto;
}

.create-comment-modal {
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    background: rgb(231 231 231 / 4%);
    border-radius: 13px;
    margin: 0 0 0.5em 0;
}

.create-comment-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;
}

.ccf-top {
    display: flex;
    justify-content: space-between;
}

#ccf-description-input {
    padding: 0 0 15px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(156, 156, 169, 0.2);
}

.ccf-button {
    margin-top: 10px;
}
