.tweet-scroll {
  overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 13px;
    margin-top: .8em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.tweet-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tweet-scroll > a {
  padding: 10px 0 0 0;
}

.tweet-embed {
  background: rgba(112, 112, 124, 0.2);
}

.tweet-embed > blockquote {
  background: rgba(112, 112, 124, 0.2) !important;
}

@media all and (max-width: 900px) {
  .tweet-scroll {
    max-height: 575px;
  }
}
