.tabs {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.toggle {
    display: flex;
    margin-bottom: .8em;
}

.toggle > li {
    font-style: normal;
    font-size: .9em;
    padding: 0px 1em 0px 0px;
    margin-left: 0px;
    transition: 0.2s ease;
}

.toggle > li:hover {
    color: #fff;
}

.toggle * {
    cursor: pointer;
}

.tabactive {
    color: #fff;
}

.tabinactive {
    color: rgba(156, 156, 169, 0.74);
}

.outlet {
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  border-radius: 13px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.outlet::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

/* Stack the layout on small devices/viewports. */
@media all and (max-width: 900px) {
    .outlet {
        max-height: 495px;
    }
}