.planetary-section-component {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8% auto;
    color: #fff;
}

.planetary-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 10%;
    grid-gap: 5%;
}

.planetary-info-container.text-right {
    padding-right: 5%;
    order: 2;
}

.planetary-image-container {
    flex-grow: 1;
    order: 1;
}

.planetary-image-container img {
    width: 100%;
}

.planetary-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
}

.planetary-info-title,
.planetary-info-desc {
    padding-bottom: 10px;
}

.planetary-info-title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 114%;
}

.planetary-info-desc {
    width: 100%;
    overflow-wrap: break-word;
}

@media (max-width: 1200px) {
    .planetary-info-container,
    .planetary-image-container {
        width: 50%;
    }
    .planetary-voting-section-container .planetary-grid-container {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 900px) {
    .planetary-section-container {
        flex-direction: column;
    }
    .planetary-image-container {
        width: 100%;
        order: 0;
    }
    .planetary-section-component:nth-col(2) {
        flex-direction: column-reverse;
    }
    .planetary-info-container {
        width: 100%;
        margin-bottom: 5%;
        order: 1;
    }
    .planetary-voting-section-container .planetary-grid-container {
        width: 100%;
        order: 1;
    }
}

@media (max-width: 450px) {
    .pow-sec {
        width: 85%;
    }
}
