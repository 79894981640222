.planet-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.planet-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.7vw;
  width: 100%;
  padding: 1.7%;
}

.planet-container {
  background: rgba(44, 44, 44, 0.28);
  border: 1px solid rgba(231, 246, 255, 0.2);
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.planet-img-container {
  width: 100%;
  padding-top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px 13px 0 0;
}

.card-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  padding: 1em .6em;
  grid-gap: .9em;
}

.btn-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: .5em;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.main-planet-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4em;
  width: 35%;
  text-align: center;
}

.main-sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}

.planet-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 174%;
  text-align: center;
  display: none;
}

.planet-title {
  line-height: 1.5;
}

.planet-tokens {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  text-align: center;
  margin-top: -0.5em;
}

@media (max-width: 1370px) {
  .planet-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1100px) {
  .planet-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .planet-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-planet-info {
    width: 100%;
    padding: 0 10%
  }
  .planet-section {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .card-content .planetary-btn {
      width: 100%;
  }
  .card-content .planetary-btn a {
      font-size: 1em;
  }
}