.prop-connect-card {
    max-width: inherit;
    background-color: #ffffff00;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0px 1rem 1.5rem rgb(0 0 0 / 50%);
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 13px;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    font-family: aktiv-grotesk, sans-serif;
    margin-bottom: 18px;
    align-items: center;
    justify-content: center;
    padding: 2vw 2vw 0;
}

.walletdetails {
    width: 100%;
}

.prop-img {
    float: left;
    width: 0.8em;
    font-size: 700%;
    line-height: 100%;
    padding-right: 20px;
}

.prop-img2 {
    cursor: pointer;
}

.walletaddress {
    font-style: normal;
    font-weight: 500;
    font-size: 24.8889px;
    line-height: 22px;
    padding-top: 11px;
}

.details {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    padding-top: 5px;
    cursor: pointer;
}

.address {
    border-right: 1px solid rgba(156, 156, 169, 0.74);
    padding-right: 10px;
    margin-right: 10px;
}

/* tabs */
.tabs {
    padding-top: 14px;
    /* overflow-y: scroll; */
}

.toggle {
    display: flex;
    cursor: pointer;
    position: sticky;
    transition: 0.2s ease;
}

.toggle > p {
    font-size: 0.9em;
    padding: 0px 1em 0px 0px;
    transition: 0.2s ease;
    margin: 0;
}

.toggle > p:hover {
    color: white;
}

.proposals {
    color: #fff;
}

.holdings {
    color: rgba(156, 156, 169, 0.74);
}

.proposals-scroll {
    overflow-y: auto;
    max-height: 100%;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    padding-bottom: calc(2vw - 0.6em);
}

.proposals-scroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.create-first {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 13px;
    height: 200px;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
}

.create-proposal-button2 {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 38px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #000000;
}

@media (max-width: 797px) {
    .prop-connect-card {
        margin-bottom: 0;
    }
}
