a {
    text-decoration: none;
}
li {
    list-style: none;
}

/* TOP NAVBAR STYLING STARTS */
.top-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin: 0;
}

.nav-links a {
    color: #fff;
}

/* LOGO */
.logo-container {
    font-size: 32px;
    display: flex;
    width: 230px;
}

.logo-container > img {
    margin-right: 10px;
}

/* TOP NAVBAR MENU */
.default-menu {
    color: #9c9ca9bd;
    transition: 0.2s ease;
}

.active-nav {
    color: #fff !important;
}

.main-menu a.active-nav:before,
.main-menu a:hover:before {
    width: 100%;
}

.menu {
    display: flex;
    gap: 20px;
}

.menu a {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 174%;
}

.menu a:hover {
    text-decoration: underline;
}

/* Contract Name */
.contract-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 8px;

    position: static;
    width: 239.59px;
    height: 33px;
    right: 1266.41px;
    top: 0px;

    /* Grey 4 */

    border: 2px solid rgba(156, 156, 169, 0.2);
    box-sizing: border-box;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin: 0px 645px; */
}

.contract-name:hover {
    text-decoration: underline;
    text-decoration-color: #fff;
}

.contract-logo {
    position: static;
    width: 8.59px;
    height: 14px;
    left: 8px;
    top: 9.5px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 6px;
}

.contract-address {
    position: static;
    width: 209px;
    height: 19px;
    left: 22.59px;
    top: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 174%;

    /* White */
    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 2px;
}

/* main navbar */
.main-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 2% 2.5% 1%;
    width: 100%;
}

/* LOGO */
.logo {
    font-weight: 700;
    font-size: 25px;
    color: #ffffff;
    display: flex;
    pointer-events: none;
}

.logo-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-text {
    margin: 0 10px;
    padding-top: 3px;
}

.planet-arrowdown {
    display: none;
}

/* main-navbar menu */
.main-menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
}

.main-menu li {
    margin: 5px 14px;
}

/* .main-menu li:last-child {
    display: none;
} */

.main-menu a:hover {
    color: #fff !important;
}

.main-menu a {
    color: rgba(156, 156, 169, 0.741);
    font-family: aktiv-grotesk, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    position: relative;
}

.main-menu a:before {
    content: "";
    position: absolute;
    width: 0;
    bottom: -0.5em;
    height: 1px;
    background-color: white;
    left: 0;
    right: 0;
    margin: auto;
    transition: width 0.2s ease;
}

a.active {
    margin: 0px;
}

.nav-active {
    color: #ffffff;
    align-items: center;
}

.nav-active > img {
    width: 39px;
    height: 39px;
}

.disabled-link {
    pointer-events: none;
}

/* Hamburger */
.hamburger {
    display: none;
}

@media (max-width: 900px) {
    /* everything smaller than a laptop */
    .main-navbar {
        position: fixed;
        z-index: 1;
        top: 0;
    }

    .main-menu a {
        font-size: 15px;
    }

    .nav-active {
        font-size: 14px;
    }

    .main-navbar:before {
        content: "";
        width: 100%;
        position: absolute;
        height: 150%;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.7950367647058824) 100%
        );
        left: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
    }

    .main-menu li {
        margin: 5px 0 5px 5px;
    }

    .overview-connect {
        transition: display ease 0.2s;
    }
    .logo {
        pointer-events: all;
    }
    /*
    .logo {
        border: 1px solid rgba(255, 245, 231, 0.2);
        border-radius: 54px;
        align-items: center;
        position: absolute;
        padding: 5px 19px 1px 24px;
        justify-content: space-between;
        cursor: pointer;
    }
*/
    .logo-text {
        margin-bottom: 5px;
    }
    .planet-arrowdown {
        display: initial;
        margin: 5px 0 0 30px;
    }
}

@media (max-width: 797px) {
    /* up to tablet size */
    .mainnav-links,
    .overview-connect {
        display: none;
    }

    .main-menu a:before {
        content: none;
    }

    .mainnav-links.expanded {
        display: block;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 2.5%;
        width: 95%;
        height: calc(100vh - 70px - 2.5vw);
        background: rgba(20, 20, 20, 0.8);
        -webkit-backdrop-filter: blur(5px) brightness(1%);
        backdrop-filter: blur(5px) brightness(1%);
        border: 1px solid rgba(156, 156, 169, 0.2);
        border-radius: 13px;
        z-index: 31;
        animation: slidein 0.5s;
    }

    .main-menu.expanded {
        display: block;
        padding: 0.8em 0;
    }

    .main-menu.expanded a:hover,
    .main-menu.expanded a.active-nav,
    .main-menu a {
        text-decoration: none;
        border-left: 1px solid white;
        padding-left: 0.8em;
    }

    .main-menu a {
        border-color: transparent;
        width: 100%;
    }

    .main-menu.expanded li {
        padding-left: 2%;
    }

    .hamburger {
        display: inline;
        margin-left: auto;
    }

    .hamburger.expanded {
        border: 1px solid rgba(255, 245, 231, 0.2);
        border-radius: 50%;
        padding: 10px;
    }

    .active.expanded span {
        margin-right: auto;
    }
    .active.expanded img {
        margin-right: auto;
        margin-left: -30em;
    }
}
