/* PAGINATION CONTAINER */
#pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
    align-items: center;
    margin-bottom: 2%;
}

.pagination-numbers-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    grid-gap: 1em;
}

.pagination-number-current {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 11px 14px 11px 15px;
    border: 1px solid white;
    border-radius: 72px;
    transition: 0.2s ease;
    width: 3em;
    height: 3em;
}

.pagination-number {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 11px 14px 11px 15px;
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 72px;
    transition: 0.2s ease;
    width: 3em;
    height: 3em;
}

.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 72px;
    padding: 1em 1.2em;
    color: white;
    transition: 0.2s ease;
    font-weight: 700;
}

.pagination-button:hover,
.pagination-button:focus,
.pagination-number:hover,
.pagination-number:focus {
    border: 1px solid rgba(156, 156, 169, 0.7);

}


/* MOBILE CSS */
@media only screen and (max-width: 600px) {
    .pagination-numbers-container {
        /* border: 2px solid green; */
    }
    .pagination-number-current {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 9px;
        border: 1px solid white;
        border-radius: 72px;
        transition: 0.2s ease;
        width: 3em;
        height: 3em;
    }
    .pagination-number {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 9px;
        border: 1px solid rgba(156, 156, 169, 0.2);
        border-radius: 72px;
        transition: 0.2s ease;
        width: 3em;
        height: 3em;
    }
}


