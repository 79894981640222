.planet-status {
    display: flex;
    flex-direction: column;
    padding: 1.2em;
    margin-bottom: 4%;
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 13px;
}

.planet-status-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.planet-status-title h3 {
    font-size: 1em;
}

.planet-status-light {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 174%;
    grid-gap: 0.3em;
    color: #ffffff;
}

.planet-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 12px 14px;
    background-color: rgba(112, 112, 124, 0.2);
    border-radius: 6px;
    margin: 20px 0px;
}

.planet-results span {
    font-style: normal;
    font-weight: 500;
    font-size: 0.65em;
    line-height: 174%;
    color: #ffffff;
}

.votes {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    gap: 25px;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 26px;
    letter-spacing: -0.009em;
    color: #ffffff;
}

.planet-quorum-met {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.planet-quorum-met img {
    height: 10px;
}

.planet-quorum-met span:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 174%;
    color: #ffffff;
    align-items: center;
}

.planet-quorum-met span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 174%;
    color: rgba(255, 245, 231, 0.74);
}

/* .planet-status > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 174%;
}

.planet-status > span:last-child {
    color: rgba(156, 156, 169, 0.74);
} */

.grey-circle {
    align-items: center;
    margin-right: 5px;
}

@media (max-width: 797px) {
    .planet-status {
        margin-bottom: 0;
    }
}
