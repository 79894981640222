.footer-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 0;
    padding: 1.5% 2.5% 2%;
    background-color: transparent;
    background: linear-gradient(0deg, rgba(0,0,0,0.8001794467787114) 0%, rgba(0,0,0,0) 100%);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 174%;
    position: relative;
}

.footer-container span,
a {
    text-decoration: none;
    color: rgba(156, 156, 169, 0.741);
}

.footer-container a:hover {
    color: #fff !important;
    text-decoration: underline;
}

.footer-container a ~ a {
    margin-left: 30px;
}

.footer-left {
    flex: 30%;
    display: flex;
}

.footer-left > img {
    margin: 0 8px 0 0;
}

.footer-middle {
    flex: 30%;
    text-align: center;
}

.footer-right {
    flex: 40%;
    text-align: right;
}

@media screen and (min-width: 1700px) {
    .foot-pos {
        position: relative;
    }
}

/* Stack the layout on small devices/viewports. */
@media all and (max-width: 900px) {
    .footer-container a ~ a {
        margin-left: 24px;
    }
    .footer-left {
        flex: 20%;
    }
    .footer-middle {
        flex: 20%;
    }
    .footer-middle,
    .footer-right {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 797px) {
    .footer-container {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        padding: 5% 2.5% 7%;
    }

    .footer-middle,
    .footer-right {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media (max-width: 426px) {
    .foot-pos {
        position: relative;
    }
}
