.planet-carousel {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planet-carousel img {
    width: 100%;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.ciclegraph {
    position: relative;
    width: 100%;
    padding-top: 100%;
    height: auto;
}

.ciclegraph:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px * 2);
    height: calc(100% - 2px * 2);
    border: 1px solid rgba(156, 156, 169, 0.4);
    backdrop-filter: blur(29.8288px);
    border-radius: 50%;
}

.ciclegraph > .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    height: 20%;
    max-width: 8em;
    max-height: 8em;
    margin: calc(-20% / 2);
    background: transparent;
    -webkit-backdrop-filter: blur(29.8288px);
    backdrop-filter: blur(29.8288px);
    border: 1px solid rgba(128, 126, 126, 0.28);
    border-radius: 89.4865px;
    transition: border 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

/* .circle:hover {
  cursor: pointer;
  border-top: 2px solid white;
  border-left: 2px solid;
  border-right: 1px dashed;
  border-bottom-style: none;
} */

.planet-details-carousel {
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color: transparent;
    z-index: 2;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    grid-gap: 6%;
}

.planet-details-carousel img {
    padding: 4%;
    box-sizing: content-box;
    width: 1em;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.2s ease;
}

.planet-details-carousel img:hover {
    opacity: 1;
}

.pc-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
}

.pc-details h3 {
    text-align: left;
    margin: 0 0 10px 0;
    transition: 2s;
}

.pc-details span {
    text-align: center;
    font-weight: 400;
    font-size: 14.4001px;
    line-height: 180%;
}

.pc-details span.label {
    color: rgba(156, 156, 169, 0.74);
    margin-right: 0.5em;
    white-space: nowrap;
}

@media screen and (max-width: 900px) {
    .planet-carousel {
        margin-top: 15%;
        margin-bottom: 15%;
    }
    .pc-details {
        width: 50%;
    }
    .planet-details-carousel {
        grid-gap: 0;
    }
    .ciclegraph > circle {
        backdrop-filter: blur(2px);
    }
    .ciclegraph:before {
        backdrop-filter: blur(2px);
    }
}
