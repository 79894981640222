.treasury-container-proposals {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
}

.treasury-content {
    width: 60%;
}

.content {
    /* margin-left: 150px; */
    /* height: 1000px; */
    color: #f1f1f1;
    /* flex: 83%;
    padding-right: 1%; */
}

.treasury-portfolio-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
}

.treasury-info {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 10px;
}

.treasury-info button {
    align-self: flex-start;
}
/*
.sidebar {
    flex: 17%;
    background-color: inherit;
}

.sidebar a {
    display: inline-block;
    min-width: 216px;
    max-width: 216px;
    color: white;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(156, 156, 169, 0.4);
    backdrop-filter: blur(29px);
    border-radius: 52px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebar a.active {
    border: 1px solid #ffffff;
    color: white;
}

.sidebar a:hover:not(.active) {
    border: 1px solid #ffffff;
    color: white;
} */

.col {
    float: left;
}

.link > .images {
    width: 30%;
}

.links > .planet-text {
    width: 65%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    padding-left: 5px;
}

.links > .planet-number {
    width: 5%;
    padding: 2px 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(156, 156, 169, 0.74);
}

/* Clear floats after the columns */
.links:after {
    content: "";
    display: table;
    clear: both;
}

@media screen and (max-width: 900px) {
    .treasury-portfolio-tab {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .treasury-content {
        width: 100%;
    }
    .treasury-info {
        width: 100%;
    }
    .treasury-container-proposals {
        margin-top: 0;
        padding: max(2%, 4vh) 0;
    }
}

@media screen and (max-width: 797px) {
    .treasury-container-proposals {
        margin-bottom: 100px;
    }
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar a {
        float: left;
    }
    div.content {
        margin-left: 0;
    }
}
