.popup-wrapper {
    position: fixed;
    width: 300px;
    padding: 0.75em;
    border-radius: 13px;
    background: rgba(10, 10, 10, 1);
    backdrop-filter: blur(29px);
    border: 1px solid rgba(156, 156, 169, 0.2);
    z-index: 1500;
}

.popup-item {
    overflow: hidden;
}

.popup-item span {
    border-left: 2px solid #dd3434;
    padding-left: 10px;
}
