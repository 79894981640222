.comments-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3% 0;
    font-style: normal;
    font-weight: 400;
    font-size: calc(15px + (20 - 15) * (100vw - 900px) / (1440 - 900));
    /* line-height: 18px; */
    cursor: pointer;
}

.comments-toggle > .top-comments {
    flex: 13%;
    padding-top: 10px;
}

.comments-toggle > .recent-comments {
    flex: 34%;
    padding-top: 10px;
    color: gray;
}

.comments-toggle > .add-button {
    float: right;
}

.comments-scroll {
    flex-grow: 1;
    overflow: scroll;
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 13px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    backdrop-filter: blur(29px);
}

.comments-scroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.no-comments-message {
    display: flex;
    justify-content: center;
    padding: 8% 3%;
}

/* ADD COMMENT BUTTON */

.addComment-plus {
    margin-right: 0.5em;
    width: 0.8em;
    height: 0.8em;
}

.add-comment-button {
    background: inherit;
    color: #ffffff;
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 72px;
    padding: 0.8em 1.2em 0.9em 1.2em;
    transition: 0.2s ease;
}

.add-comment-button:hover {
    border-color: white;
}

.add-comment-button-inactive {
    background: inherit;
    color: rgba(156, 156, 169, 0.4);
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 72px;
    padding: 0.8em 1.2em 0.9em 1.2em;
    transition: 0.2s ease;
}

.add-comment-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 16px; */
    color: #ffffff;
}

.add-comment-text-inactive {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 16px; */
    color: gray;
}

.add-comment-text > p {
    padding-top: 0.1em;
    margin: 0;
}

@media only screen and (min-width: 1440px) {
    .comments-toggle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 797px) {
    .comments-toggle {
        font-size: 16px;
        padding: 3%;
    }
    .no-comments-message {
        display: flex;
        justify-content: center;
        padding: 15% 3%;
    }

    .addComment-plus {
        height: 12px;
        width: 12px;
        /* margin-ri */
    }

    .add-comment-text {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        /* line-height: 16px; */
        color: #ffffff;
    }

    .add-comment-text-inactive {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        /* line-height: 16px; */
        color: gray;
    }

    .add-comment-text > p {
        padding-top: 2px;
    }
}
