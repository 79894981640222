.quorum-planets-container {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    padding: 5%;
    margin-top: 4%;
    /* align-items: center; */
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 13px;
}

.quorum-status-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.proposalpage-infosection h3 {
    font-size: 18px;
}

.quorum-status-light {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 174%;
    grid-gap: 0.3em;
    color: #ffffff;
}

.quorum-status-text {
    font-size: 0.65em;
    padding: 4% 0 0 0;
}

.quorum-status-text a {
    text-decoration: underline;
}

.quorum-poll {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-top: 5%;
    margin-bottom: 5%;
}

.quorum-planet-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 8px;
    overflow: hidden;
}

.quorum-planet-status > span {
    font-weight: 400;
    font-size: 0.65em;
    line-height: 174%;
    text-overflow: ellipsis;
}

.quorum-planet-status > span:last-child {
    color: rgba(156, 156, 169, 0.74);
}

.quorum-poll li.active {
    background-color: rgba(112, 112, 124, 0.2);
    border-radius: 10px;
}

.quorum-poll span {
    align-items: center;
}

.grey-circle {
    align-items: center;
    margin-right: 5px;
}

.quorum-planet-status:hover {
    cursor: pointer;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 10px;
}

.quorum-planet-status .active {
    cursor: pointer;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 10px;
}

.quorum-poll .quorum-planet-status {
    list-style: none;
    transition: 0.2s ease;
}

.header-flex {
    display: flex;
    flex-direction: column;
}

.header-flex span {
    font-weight: 400;
    font-size: 0.6em;
    line-height: 174%;
}

.carousel {
    margin: 0px;
    overflow: hidden;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 10px;
}

.carouselSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    max-width: 300px;
}

.slide {
    padding: 15px;
    display: inline-block;
    height: auto;
    width: 100%;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 6px;
}

.slide-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slide-header {
    font-weight: 700;
    font-size: 1em;
    line-height: 22px;
}

.slide-quorum-info {
    font-weight: 400;
    font-size: 0.6em;
    line-height: 174%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.slide-quorum-info div {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.slide-quorum-info div span:last-child {
    color: rgba(156, 156, 169, 0.74);
}

.slide-header {
    margin-bottom: 4%;
}

.slide-progress-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4%;
}

.slide-progress-container div {
    font-weight: 500;
    font-size: 1em;
}

.spc-image {
    height: 18px;
    width: 20px;
    margin-right: 3px;
}

.vertical-align-text-top {
    vertical-align: text-top;
}

.spc-image-sm {
    height: 8px;
    width: 22px;
    margin-left: -8px;
}

.planet-quorum-img {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
}

@media screen and (min-width: 1760px) {
    .carouselSlider {
        max-width: 400px;
    }
}

@media screen and (min-width: 2250px) {
    .carouselSlider {
        max-width: 500px;
    }
}

@media screen and (max-width: 797px) {
    .carouselSlider {
        max-width: 600px;
    }
}
