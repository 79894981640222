/* cards */
.comment-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 1.3em;
    background: rgba(112, 112, 124, 0.2);
    border: 1px solid transparent;
    border-radius: 13px;
    margin: 1em 0 0 0;
    transition: 0.2s ease;
}

.comment-card:first-child {
    margin-top: 0;
}

.comment-card:hover {
    border: 1px solid rgba(239, 239, 248, 0.2);
}

.overview-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overview-vote-cont {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.overview-vote-cont div {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    gap: 2px;
}

.flex-item-left {
    flex: 60%;
    /* margin-right: 30%; */
}

.flex-middleitem-left {
    flex: 80%;
}

/* .flex-item-right {
    flex: 40%;
} */
.flex-item-right-second .details-container span {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.flex-middleitem-right {
    flex: 20%;
}

.details-container {
    display: flex;
    align-items: center;
}

.details-contd {
    padding-top: 19px;
}

.details-container > span {
    font-size: 10px;
    white-space: nowrap;
    line-height: 1.2;
}

.address {
    display: inline-block;
    border-right: 1px solid rgba(156, 156, 169, 0.74);
    padding: 0px 0.8em 0px 0.5em;
    margin-right: 0.8em;
    color: #ffffff;
    font-weight: 700;
}

.address1 {
    font-weight: initial;
    border-right: 1px solid rgba(156, 156, 169, 0.74);
    padding: 0px 0.8em 0px 0em;
    margin-right: 0.8em;
}

.other-span {
    padding: 2px 0;
    font-size: 10px;
}

.test .other-span,
.test .address {
    font-size: 7px;
}

.active-status {
    display: flex;
    gap: 3px;
    align-items: center;
}

.prop-img3 {
    margin-top: 5.5px;
    margin-right: 2px;
}

.proposals-planet {
    margin-right: 5px;
}
.tags-cont {
    display: flex;
    flex-direction: row;
}

.addressd {
    border-left: 1px solid rgba(255, 245, 231, 0.4);
    padding-left: 4px;
    font-size: 10px;
    line-height: 1.2;
    padding-right: 4px;
    color: rgba(156, 156, 169, 0.74);
}

.addressd2 {
    border-left: 1px solid rgba(255, 245, 231, 0.4);
    padding-left: 4px;
    font-size: 10px;
    line-height: 1.2;
    padding-right: 4px;
    color: #fff;
}

.prop-text {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(156, 156, 169, 0.74);
    color: #ffffff;
    line-height: 22px;
    font-size: 0.95em;
    font-weight: 700;
    margin-top: 10px;
}

.flex-item-left-second {
    flex: 60%;
    /* margin-right: 32%; */
}
.flex-middleitem-left-second {
    flex: 75%;
}

/* .flex-item-right-second {
    flex: 40%;
} */
/* .flex-middleitem-right-second {
    flex: 25%;
} */
.progress-section {
    display: flex;
    color: rgba(156, 156, 169, 0.74);
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1600px) {
    .flex-item-left,
    .flex-item-left-second {
        margin-right: 2%;
    }
    .flex-middleitem-right {
        flex: 24% 1;
    }
    .prop-text {
        line-height: 19px;
        font-size: 0.9em;
    }
}

@media (max-width: 797px) {
    .flex-container {
        flex-direction: column;
    }
    /* .flex-item-right {
        flex: 20% 1;
    } */
    .flex-middleitem-right {
        flex: 21% 1;
    }
    /* .flex-item-right {
        flex: 30% 1;
    } */
    /* .flex-middleitem-right-second {
        flex: 50% 1;
    } */
    .flex-middleitem-right {
        flex: 45% 1;
    }
    .flex-item-left {
        margin-right: 20%;
    }
}

@media (max-width: 425px) {
    .details-container > span {
        font-size: 10px;
    }
    .test .other-span,
    .test .address {
        font-size: 10px;
    }
}
