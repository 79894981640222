/* Other */
.button-pointer {
    cursor: pointer;
}
/* Proposal Section */
#proposal-section {
    min-height: inherit;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.planet-nav {
    width: 20%;
}
#proposal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}
.proposal-scroll {
    overflow: scroll;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.proposal-scroll::-webkit-scrollbar {
    display: none;
}
.outlet-prop {
    overflow-y: auto;
    max-height: 730px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    /* height: inherit; */
    border-radius: 13px;
}
.outlet-prop::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

#proposal-section,
#proposal-container {
    flex-grow: 1;
    min-height: 100%;
}
