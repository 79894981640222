.info-container {
  border: 1px solid rgba(156, 156, 169, 0.2);
  border-radius: 13px;
  padding: 15px;
  margin: 15px 0;
  background-color: inherit;
}

.info-container h2 {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 1em;
}

.info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  padding-bottom: 8px;
}

.info-row > span > img {
  margin-right: 0;
}
