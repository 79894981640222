.container-holdings {
  display: flex;
  flex-direction: row;
  grid-gap: .6em;
}

.flexed {
  background: rgba(44, 44, 44, 0.28);
  border: 1px solid rgba(156, 156, 169, 0.2);
  border-radius: 8px;
  padding: 1em .5em;
  flex: 30%;
  text-align: center;
  width: 100%;
  margin-bottom: .6em;
}

.flexed > img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  object-position: center;
}

.flexed > p {
  font-weight: 700;
  font-size: .9em;
  margin-top: 0;
  margin-bottom: .2em;
}

.flexed > h6 {
  font-weight: 400;
  font-size: 9px;
}

.filler {
  background: transparent;
  backdrop-filter: blur(29px);
  border-radius: 8px;
  padding: 10px;
  margin: 0 0 10px 0;
  flex: 30%;
  text-align: center;
}