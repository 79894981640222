.planetary-btn {
    background: transparent;
    border: 1px solid rgba(156, 156, 169, 0.4);
    border-radius: 30px;
    transition: 0.2s ease;
}

*:not(.btn-container) > * + .planetary-btn {
    margin-top: 1em;
}

.planetary-btn a {
    text-decoration: none;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding: .9em 1.3em; 
    box-sizing: content-box;
    display: block;
}

.planetary-btn:hover {
    background: black;
    border: 1px solid #ffffff;
    color: white;
    /* box-shadow: 0 0.5em 0.4em -0.4em #fff; */
    transform: scale(1.05);
    cursor: pointer;
}