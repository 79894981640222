/* FILTER BAR */
.filter-bar-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.4%;
}

.fbc-left {
  display: flex;
  align-items: center;
  width: 60%;
}

.fbc-left > div {
    margin-left: 3.5%;
}

.fbc-text {
    white-space: nowrap;
}
  
.filter-button {
    font-weight: 400;
    font-size: 16px;
    width: auto;
    border: 1px solid #9c9ca933;
    border-radius: 53px;
    padding: 0.5% 1% 0.5% 1.5%;
    transition-duration: 0.4s;
}

.filter-select {
    font-weight: 400;
    font-size: 16px;
    min-width: 180px;
    border: 1px solid #9c9ca933;
    border-radius: 53px;
    padding: 0.5% 1% 0.5% 1.5%;
    transition-duration: 0.4s;
    cursor: pointer;
}

.filter-select * {
    cursor: pointer;
}

.filter-button:hover {
    border: 1px solid white;
    cursor: pointer;
}

.create-proposal-button {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: white;
    border: 1px solid #9c9ca933;
    border-radius: 53px;
    padding: 12px 16px 12px 14px;
}

.create-proposal-button:hover {
    border: 1px solid white;
}

.create-proposal-button-inactive {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: rgba(156, 156, 169, 0.74);
    border: 1px solid #9c9ca933;
    border-radius: 53px;
    padding: 12px 16px 12px 14px;
}

.create-proposal-plus {
    margin-right: 6px;
    font-size: 20px;
}

@media only screen and (max-width: 797px) {
    /* FILTER BAR SECTION */
    .filter-bar-bg {
        background: rgba(20, 20, 20, 0.8);
        -webkit-backdrop-filter: blur(5px) brightness(1%);
        backdrop-filter: blur(5px) brightness(1%);
        border-radius: 13px;
        position: fixed;
        width: 95%;
        height: calc(100vh - 128px - 2.5vw);
        left: 2.5vw;
        top: 128px;
        z-index: 1;
        animation: slidein 0.6s;
        padding: 3%;
    }

    /* .filter-bar-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        border: 2px solid green;
    } */

    .filter-bar-hide {
        display: none;
    }

    .filter-select {
        margin-left: 0;
        margin-bottom: .5em;
        min-width: 100px;
        font-size: 0.8em;
    }

    .filter-button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 2%;
        width: 27%;
    }

}


/* ANIMATIONS */
@keyframes slidein {
    from {
        margin-top: +800px;
        opacity: 0;
    }
    to {
        margin-top: 0px;
        opacity: 1;
    }
}

/* @keyframes upsideDown {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */
