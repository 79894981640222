.App {
    background-color: black;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
    font-weight: 700;
}

h1 {
    font-size: calc(25px + (40 - 25) * (100vw - 400px) / (1440 - 400));
}

h2 {
    font-size: calc(21px + (32 - 21) * (100vw - 400px) / (1440 - 400));
}

h3 {
    font-size: calc(19px + (28 - 19) * (100vw - 400px) / (1440 - 400));
}

p {
    margin: 1em 0;
}

p:last-child {
    margin-bottom: 0;
}

p:first-of-type:first-child {
    margin-top: 0;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body ::selection {
    background: rgba(255, 255, 255, 0.15);
  }  

.App > * {
    max-width: 1700px;
    margin: auto;
}

.sus {
    height: 90vh;
}

@media screen and (max-width: 900px) {
    .App {
        padding-top: 70px;
    }
}

@media screen and (max-width: 797px) {
    .App {
        overflow: visible;
        height: auto;
    }
}