/* cards */

.comments-card {
  padding: 15px 20px;
  background: rgb(231 231 231 / 4%);
  border-radius: 13px;
  margin: 0 0 0.5em 0;
}

.comment-image {
  float: left;
  margin-right: 20px;
  pointer-events: none;
}

.flex-container-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-item-left-c {
  width: 90%;
}

.flex-item-right-c {
  display: flex;
  align-items: center;
}

.details-container-c {
  display: flex;
}
.details-container-c span {
  cursor: pointer;
}

.address-c {
  display: inline-block;
  padding: 0 0.8em 0 0;
  margin-right: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 0.9em;
  /* text-overflow: ellipsis; */
  max-width: calc(100% - 4em);
  /* overflow: hidden; */
}

.tooltip2 {
  position: relative;
  cursor: pointer;
}
.tooltip-text2 {
  position: absolute;
  background-color: rgb(22, 21, 21);
  backdrop-filter: blur(29px);
  border: 1px solid rgba(156, 156, 169, 0.2);
  padding: 0 7px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.tooltip2:hover .tooltip-text2 {
  top: -100%;
  left: 40%;
  visibility: visible;
  opacity: 1;
}

.other-span-c {
  color: rgba(156, 156, 169, 0.74);
  font-size: 11px;
}

.likes {
  color: rgba(156, 156, 169, 0.74);
  /* padding: 6px 0; */
  font-size: 11px;
  /* margin-right: 30px; */
}

.comm-img-c {
  margin-right: 0.3em;
  cursor: pointer;
}

.comm-img-c:hover {
  background-image: url(../../../../../images/greyLove.svg);
  transform: scale(1.1);
}

.options {
  font-size: 30px;
  color: rgba(156, 156, 169, 0.74);
  margin-top: -15px;
}

.comment {
  padding: 5px 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 174%;
  margin-left: 60px;
}

.comment-text {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1600px) {
  .flex-item-left {
    margin-right: 2%;
  }
}
@media (max-width: 950px) {
  .flex-item-left-c .details-container-c {
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .comments-card {
    margin: 0 4% 20px 4%;
  }
}
