.carousel-container {
    display: flex;
    flex-direction: row;
    padding: max(2%, 4vh) 10%;
    background-color: transparent;
    font-style: normal;
    color: #fff;
    align-items: center;
    grid-gap: 8%;
}

.carousel-left {
    flex: 37%;
}
/*
.carousel-left > h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 114%;
    text-align: left;
}

.carousel-left > p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    margin: 1.1em 0;
}
*/
.carousel-right {
    flex: 63%;
    position: relative;
    padding: 3%;
    max-width: 80vh;
}

@media screen and (max-width: 900px) {
    .carousel-container {
        flex-direction: column;
    }
    .carousel-left,
    .carousel-right {
        width: 100%;
        text-align: left;
    }
}
